import { Box, Button, Typography } from '@mui/material';
import { ContactCreateForCompanyResponse, ContactCreateResponse } from '@/shared/types/controllers/spa/SPAContactControllerTypes';
type NewCustomerAddedProps = {
  contact: ContactCreateResponse['contact'] | ContactCreateForCompanyResponse['contact'];
  onClose: () => void;
};
export const NewCustomerAdded = ({
  contact,
  onClose
}: NewCustomerAddedProps) => {
  return <Box sx={{
    p: 3,
    maxWidth: 400,
    mx: 'auto',
    my: 4
  }} data-sentry-element="Box" data-sentry-component="NewCustomerAdded" data-sentry-source-file="NewCustomerAdded.tsx">
      <Typography variant="h5" mb={4} data-sentry-element="Typography" data-sentry-source-file="NewCustomerAdded.tsx">
        New customer was added!
      </Typography>

      <Box my={2} data-sentry-element="Box" data-sentry-source-file="NewCustomerAdded.tsx">
        <Typography variant="bodyMedium" mb={2} data-sentry-element="Typography" data-sentry-source-file="NewCustomerAdded.tsx">
          Customer Details:
        </Typography>
        <Typography data-sentry-element="Typography" data-sentry-source-file="NewCustomerAdded.tsx">
          Name: {`${contact.firstName} ${contact.lastName}`}
        </Typography>
        <Typography data-sentry-element="Typography" data-sentry-source-file="NewCustomerAdded.tsx">Email: {contact.email}</Typography>
        {contact.phone && <Typography>Contact Number: {contact.phone}</Typography>}
      </Box>
      <Box my={2} data-sentry-element="Box" data-sentry-source-file="NewCustomerAdded.tsx">
        <Typography variant="bodyMedium" mb={2} data-sentry-element="Typography" data-sentry-source-file="NewCustomerAdded.tsx">
          Company Details:
        </Typography>
        {contact.companyName && <Typography>{contact.companyName}</Typography>}
        {contact.companyStreet && <Typography>{contact.companyStreet}</Typography>}
        {contact.companyStreet2 && <Typography>{contact.companyStreet2}</Typography>}
        {contact.companyCity && contact.companyState && contact.companyZipCode && <Typography>{`${contact.companyCity}, ${contact.companyState} ${contact.companyZipCode}`}</Typography>}
        {contact.companyCountry && <Typography>{contact.companyCountry}</Typography>}
        {contact.companyPhone && <Typography>{contact.companyPhone}</Typography>}
      </Box>

      <Box mt={3} data-sentry-element="Box" data-sentry-source-file="NewCustomerAdded.tsx">
        <Button onClick={onClose} color="primary" variant="outlined" fullWidth data-sentry-element="Button" data-sentry-source-file="NewCustomerAdded.tsx">
          OK
        </Button>
      </Box>
    </Box>;
};