import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';
import { Card, CardContent, Typography, Stack, Box, Button, Skeleton } from '@mui/material';
import { NumberInput } from '@qb/frontend/components/NumberInput/NumberInput';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents } from '@qb/frontend/utils/formatCurrency';
import { getFirstImage } from '@qb/frontend/utils/getFirstImage';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { isNotEmpty } from '@/shared/types/util/TypeGuards';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { Part } from './CreateNewQuoteDrawer';
const INITIAL_QUANTITY = 1;
type PartToAddCardProps = {
  part: Part;
  onAddToQuote: (part: Part, qty: number) => void;
};
export const PartToAddCard = ({
  part,
  onAddToQuote
}: PartToAddCardProps) => {
  const [quantity, setQuantity] = useState(INITIAL_QUANTITY);
  const tenantAlias = useTenantAlias();
  const {
    data: partPublicData,
    isLoading
  } = useQuery(spaQueries.part.publicData(part.id, tenantAlias));
  return <Card sx={{
    width: '100%',
    mt: 2
  }} data-sentry-element="Card" data-sentry-component="PartToAddCard" data-sentry-source-file="PartToAddCard.tsx">
      <CardContent data-sentry-element="CardContent" data-sentry-source-file="PartToAddCard.tsx">
        <Stack direction="row" spacing={4} data-sentry-element="Stack" data-sentry-source-file="PartToAddCard.tsx">
          <Stack spacing={4} data-sentry-element="Stack" data-sentry-source-file="PartToAddCard.tsx">
            <NumberInput initialValue={INITIAL_QUANTITY} onValueChange={setQuantity} data-sentry-element="NumberInput" data-sentry-source-file="PartToAddCard.tsx" />
            <Box pl={2} data-sentry-element="Box" data-sentry-source-file="PartToAddCard.tsx">
              <Image src={getFirstImage(part.images)} alt={part.partNumber} width={70} height={70} data-sentry-element="Image" data-sentry-source-file="PartToAddCard.tsx" />
            </Box>
          </Stack>
          <Box data-sentry-element="Box" data-sentry-source-file="PartToAddCard.tsx">
            <Button variant="contained" color="secondary" fullWidth onClick={() => onAddToQuote(part, quantity)} data-sentry-element="Button" data-sentry-source-file="PartToAddCard.tsx">
              Add to quote
            </Button>
            <Typography variant="h6" mt={4} mb={2} data-sentry-element="Typography" data-sentry-source-file="PartToAddCard.tsx">
              {part.partNumber}
            </Typography>
            <Typography variant="bodyMedium" sx={clampLinesSx(2)} data-sentry-element="Typography" data-sentry-source-file="PartToAddCard.tsx">
              {part.description}
            </Typography>
          </Box>
        </Stack>

        <Stack mt={2} data-sentry-element="Stack" data-sentry-source-file="PartToAddCard.tsx">
          <Row index={0} label="Manufacturer" value={<Typography variant="bodyMediumPrimary" color="text.primary">
                {part.manufacturer.name}
              </Typography>} data-sentry-element="Row" data-sentry-source-file="PartToAddCard.tsx" />

          {isLoading ? <>
              <Row index={1} label="On-hand qty" value={<Skeleton width={120} />} />
              <Row index={2} label="Product lifecycle" value={<Skeleton width={120} />} />
              <Row index={3} label="Price" value={<Skeleton width={120} />} />
            </> : <>
              <Row index={1} label="On-hand qty" value={isNotEmpty(partPublicData?.publicQtyAndPrice?.onHandQty) ? <Typography variant="bodyMedium">
                      {partPublicData.publicQtyAndPrice.onHandQty}
                    </Typography> : '-'} />
              <Row index={2} label="Product lifecycle" value={isNotEmpty(partPublicData?.part.active) ? <Typography variant="bodyMediumPrimary" color={partPublicData.part.active ? 'success.main' : 'error.main'}>
                      {partPublicData.part.active ? 'Active' : 'Not Active'}
                    </Typography> : '-'} />
              <Row index={3} label="Price" value={isNotEmpty(partPublicData?.publicQtyAndPrice?.price) ? <Typography variant="h6">
                      {formatCents(partPublicData.publicQtyAndPrice.price)}
                    </Typography> : '-'} />
            </>}
        </Stack>
      </CardContent>
    </Card>;
};
type RowProps = {
  index: number;
  label: string;
  value: React.ReactNode;
};
const getRowBackgroundColor = (index: number) => {
  return index % 2 === 0 ? 'background.default' : 'background.paper';
};
const Row = ({
  index,
  label,
  value
}: RowProps) => {
  return <Stack direction="row" justifyContent="space-between" sx={{
    backgroundColor: getRowBackgroundColor(index),
    p: 2
  }} data-sentry-element="Stack" data-sentry-component="Row" data-sentry-source-file="PartToAddCard.tsx">
      <Typography variant="bodyMedium" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="PartToAddCard.tsx">
        {label}
      </Typography>
      {value}
    </Stack>;
};