import { ListActions } from 'react-use/lib/useList';
import Image from 'next/image';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { NumberInput } from '@qb/frontend/components/NumberInput/NumberInput';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { getFirstImage } from '@qb/frontend/utils/getFirstImage';
import { PartWithQty } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/CreateNewQuoteDrawer';
type RequestedPartProps = {
  part: PartWithQty;
  index: number;
  selectedPartsListActions: ListActions<PartWithQty>;
};
export const RequestedPart = ({
  part,
  index,
  selectedPartsListActions
}: RequestedPartProps) => <>
    <Stack direction="row" spacing={2} alignItems="flex-start" data-sentry-element="Stack" data-sentry-source-file="RequestedPart.tsx">
      <Image src={getFirstImage(part.images)} alt={part.partNumber} width={50} height={50} data-sentry-element="Image" data-sentry-source-file="RequestedPart.tsx" />
      <Box data-sentry-element="Box" data-sentry-source-file="RequestedPart.tsx">
        <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="RequestedPart.tsx">{part.partNumber}</Typography>
        <Typography variant="bodyMediumPrimary" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="RequestedPart.tsx">
          {part.manufacturer.name}
        </Typography>
      </Box>
      <Typography variant="bodyMedium" color="text.secondary" sx={{
      flex: 1,
      ...clampLinesSx(3)
    }} data-sentry-element="Typography" data-sentry-source-file="RequestedPart.tsx">
        {part.description}
      </Typography>
      <Stack direction="row" alignItems="flex-end" spacing={1} data-sentry-element="Stack" data-sentry-source-file="RequestedPart.tsx">
        <Stack spacing={1} data-sentry-element="Stack" data-sentry-source-file="RequestedPart.tsx">
          <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="RequestedPart.tsx">QTY</Typography>
          <NumberInput initialValue={part.qty} onValueChange={newValue => {
          if (newValue !== part.qty) {
            selectedPartsListActions.updateAt(index, {
              ...part,
              qty: newValue
            });
          }
        }} data-sentry-element="NumberInput" data-sentry-source-file="RequestedPart.tsx" />
        </Stack>
        <IconButton color="primary" size="small" onClick={() => selectedPartsListActions.removeAt(index)} data-sentry-element="IconButton" data-sentry-source-file="RequestedPart.tsx">
          <DeleteIcon data-sentry-element="DeleteIcon" data-sentry-source-file="RequestedPart.tsx" />
        </IconButton>
      </Stack>
    </Stack>
  </>;