'use client';

import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams, useRouter } from 'next/navigation';
import { LoginView } from '../Auth/LoginView/LoginView';
export const PrivateRoute = ({
  children
}: PropsWithChildren) => {
  const router = useRouter();
  const queryParams = useSearchParams();
  const pendingUserToken = queryParams?.get('pendingUserToken');
  const {
    user,
    hasFetchedUser
  } = useSelector(({
    main
  }) => main);
  if (typeof window === 'undefined') {
    // We have no way of redirecting on the server, nor accessing the user information, better to just not render the component
    return null;
  }
  if (hasFetchedUser) {
    if (user) {
      return children;
    }
    if (pendingUserToken) {
      router.push(`/pendingUser/completeProfile?token=${pendingUserToken}`);
      return null;
    }
    return <LoginView viewType="page" />;
  }
  return null;
};